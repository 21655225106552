import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Components/Sass/main.scss'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import WelltooPro500 from './Components/Pages/WelltooPro500';
import WelltooProChoco from './Components/Pages/WelltooProChoco';
import WelltooProVanilla from './Components/Pages/WelltooProVanilla';



function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/welltoo-pro-500" element={<WelltooPro500 />} />
          <Route path="/products/welltoo-pro-choco" element={<WelltooProChoco />} />
          <Route path="/products/welltoo-pro-vanilla" element={<WelltooProVanilla />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
